<template>
  <div>
    <c-table
      ref="table"
      title="코드 목록"
      :columnSetting="false"
      :isFullScreen="false"
      :usePaging="false"
      :filtering="false"
      :isExcelDown="false"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      :selection="popupParam.type"
      rowKey="code"
      @rowDblclick="rowDblclick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="선택" icon="check" @btnClicked="select" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'codePop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'single',
          codeGroupCd: '',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'code',
            field: 'code',
            label: '코드',
            align: 'center',
            style: 'width:140px',
            sortable: false,
          },
          {
            name: 'codeNameKr',
            field: 'codeNameKr',
            label: '코드명',
            align: 'left',
            sortable: false,
          },
        ],
        data: [],
        height: '500px'
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sys.code.mst.list.url;
      this.getCodeMst();
    },
    getCodeMst() {
      this.$http.url = this.$format(this.listUrl, this.popupParam.codeGroupCd);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },() => {});
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
  }
};
</script>
